<template>
  <b-card>
    <b-row class="pt-2 mb-4">
      <b-col class="d-flex">
        <div class="d-flex align-items-center mr-2">
          <b-avatar
            size="80"
            variant="light-primary"
            class="mr-2 rounded-square"
            style="border-radius: 5px !important ;"
          >
            <feather-icon
              size="60"
              icon="UsersIcon"
            />
          </b-avatar>
          <div class="d-flex flex-column">
            <p class="card-text font-small-3 mb-0">
              Active Programs
            </p>
            <h1 class="font-weight-bolder mb-0 text-purple">
              {{ data.active_program_count }}
            </h1>
          </div>
        </div>


        <div class="d-flex align-items-center mr-2">
          <b-avatar
            size="80"
            variant="light-info"
            class="mr-2"
            style="border-radius: 5px !important ;"
          >
            <feather-icon
              size="60"
              icon="UserCheckIcon"
            />
          </b-avatar>
          <div class="d-flex flex-column">
            <p class="card-text font-small-3 mb-0">
              Active Mentors
            </p>
            <h1 class="font-weight-bolder mb-0 text-info">
              {{ data.active_mentor_count }}
            </h1>
           
          </div>
        </div>

        <div class="d-flex align-items-center mr-2">
          <b-avatar
            size="80"
            variant="light-danger"
            class="mr-2"
            style="border-radius: 5px !important ;"
          >
            <feather-icon
              size="60"
              icon="UserIcon"
            />
          </b-avatar>
          <div class="d-flex flex-column">
            <p class="card-text font-small-3 mb-0 ">
              Active Mentees
            </p>
            <h1 class="font-weight-bolder mb-0 text-danger">
              {{ data.active_mentee_count }}
            </h1>
           
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6">
        <h4>Program Surveys by lowest completion rate</h4>
        <lowest-rate-surveys />
      </b-col>

      <b-col sm="6">
        <h4>Programs expiring soon</h4>
        <expiring-soon-programs />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from "bootstrap-vue";
import LowestRateSurveys from "./LowestRateSurveys.vue";
import ExpiringSoonPrograms from "./ExpiringSoonPrograms.vue";
import reportService from "@/services/reportService";

export default {
  name: "ChampionsDashBoard",
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    LowestRateSurveys,
    ExpiringSoonPrograms
  },
  data() {
    return {
      isLoading: false,
      data: {},
    };
  },
  created() {
    this.getAdminDashboard();
  },
  methods: {
    async getAdminDashboard() {
      try {
        const response = await reportService.getAdminDashboard();
        this.data = response.data;
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Admin dashboard not loaded."));
        return;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
  .text-purple{
    color: var(--purple)
  }
  .text-info{
    color: var(--purple)
  }
  .text-danger{
    color: var(--purple)
  }
  
</style>

