<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :sort-options="{enabled: false}"
    :filter-options="{enabled: false}"
    :search-options="{enabled: false}"
    :pagination-options="{enabled: false}"
    style-class="vgt-table striped"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Actions -->
      <div
        v-if="props.column.field === 'action'"
        cols="12"
        md="2"
        class="vgt-center-align d-flex"
      >
        <b-button
          variant="outline-none"
          @click="viewProgram(props.row.id)"
        >
          <feather-icon
            icon="EyeIcon"
            size="20"
          />
        </b-button>
      </div>

      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import { mapGetters } from "vuex";
import { BButton, VBToggle } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { makeErrorToast } from "@/libs/utils";
import programsService from "@/services/programsService";
import { localeDateStringFromIsoDateTime } from '@/libs/utils';

export default {
  components: {
    VueGoodTable,
    BButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          width: "10em",
        },
        {
          label: "Expiry Date",
          field: "licence_end_date",
          width: "15em",
        },
        {
          label: "Actions",
          field: "action",
          width: "5em",
        },
      ],
      rows: [],
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),

    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await programsService.getList({
          page: 1,
          perPage: 10,
        });
        this.rows = response.data.items.map(item => ({
          ...item,
          licence_end_date: localeDateStringFromIsoDateTime(item.licence_end_date)
        }));
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        this.$toast(makeErrorToast("Programs list not loaded."));
        return;
      } finally {
        this.isLoading = false;
      }
    },
    viewProgram(id) {
      this.$router.push({
        name: "admin-program",
        params: { id }
      });
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
